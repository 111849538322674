// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import NProgress from "nprogress";
import noUiSlider from "nouislider";
import wNumb from "wnumb";

const CONTAINER  = document.querySelector(".activity-filter");
const TOGGLE     = CONTAINER ? CONTAINER.querySelector(".activity-filter__toggle") : false;
const FORM       = CONTAINER ? CONTAINER.querySelector(".activity-filter__inner") : false;
const CATS       = CONTAINER ? CONTAINER.querySelectorAll("[name='category']") : false;
const OTHER_CATS = CONTAINER ? CONTAINER.querySelectorAll("[name='other_category']") : false;
const RANGE      = CONTAINER ? CONTAINER.querySelector(".toggle-group__noUi-target") : false;
const AGE_FROM   = CONTAINER ? CONTAINER.querySelector("input[name=age_from]") : false;
const AGE_TO     = CONTAINER ? CONTAINER.querySelector("input[name=age_to]") : false;
const DOWS       = CONTAINER ? CONTAINER.querySelectorAll(".toggle-group__input[name='dow[]']") : false;
const INPUT      = CONTAINER ? CONTAINER.querySelector("#activity-filter_search-input") : false;
const ENDPOINT   = CONTAINER ? CONTAINER.querySelector("#activity-filter_endpoint-input") : false;
const RESET      = CONTAINER ? CONTAINER.querySelector(".activity-filter__reset-button") : false;
const TARGET     = document.getElementById("activity-list");

const SLIDER = RANGE && AGE_FROM && AGE_TO ? noUiSlider.create(RANGE, {
    connect: true,
    range: {
        min: 0,
        max: 18,
    },
    start: [0, 18],
    step: 1,
    tooltips: wNumb({
        decimals: 0,
        edit: (value) => {
            return value >= 18 ? `${value}+` : value;
        },
    }),
}) : false;

/**
 * Filter the form
 */
const FILTER = () => {
    CONTAINER.classList.add("is-loading");

    /**
     * Configure NProgress to display in the parent content block
     */
    NProgress.configure({
        parent: "#activity-list",
    });

    /**
     * Start NProgress
     */
    NProgress.start();

    /**
     * Increment NProgress every 500ms
     */
    const TIMER = setTimeout(() => {
        NProgress.inc();
    }, 500);

    /**
     * Identify values
     */
    const CATEGORY = CATS.length === 1 && CATS[0].type === "hidden" ? CATS[0] : Array.prototype.slice.call(CATS).filter(x => x.checked)[0];
    const OTHER_CATEGORY = OTHER_CATS.length === 1 && OTHER_CATS[0].type === "hidden" ? OTHER_CATS[0] : Array.prototype.slice.call(OTHER_CATS).filter(x => x.checked)[0];
    const CATEGORY_ID = FORM.dataset.categoryId ? FORM.dataset.categoryId : (CATEGORY ? parseInt(CATEGORY.value) : false);
    const DOW = [];
    const Q = INPUT.value;

    /**
     * Convert DOWs in to ActiveNET format
     */
    DOWS.forEach((dow) => {
        DOW.push(dow.checked ? 1 : 0);
    });

    /**
     * Construct query parameters
     */
    const PARAMS = new URLSearchParams({
        activities: true,
        age_from: AGE_FROM.value,
        age_to: AGE_TO.value,
        category_id: CATEGORY_ID,
        category: CATEGORY ? CATEGORY.value : false,
        other_category: OTHER_CATEGORY ? OTHER_CATEGORY.value : false,
        dow: DOW.join(""),
        endpoint: ENDPOINT.value,
        q: Q,
    });

    /**
     * Submit request
     */
    fetch(`${l10n.home_url}?${PARAMS.toString()}`)
        .then((response) => {
            return response.text();
        })
        .then((html) => {
            /**
             * Display result
             */
            TARGET.innerHTML = html;

            /**
             * Stop NProgress increments
             */
            clearInterval(TIMER);

            /**
             * End the NProgress
             */
            NProgress.done();

            /**
             * Remove the "loading" marker
             */
            CONTAINER.classList.remove("is-loading");
        })
        .catch((error) => {
            console.log(error);
        });
};

/**
 * Handle accordion for mobile
 */
if (CONTAINER && TOGGLE) {
    TOGGLE.addEventListener("click", (e) => {
        e.preventDefault();

        CONTAINER.classList.toggle("is-active");
    }, { passive: false });
}

/**
 * Reset the form when clicked
 */
if (RESET && FORM) {
    RESET.addEventListener("click", (e) => {
        e.preventDefault();

        /**
         * Uncheck and disable all categories
         */
        if (CATS && CATS.length > 0) {
            CATS.forEach((cat) => {
                cat.checked = false;
            });
        }

        /**
         * Reset age range
         */
        if (SLIDER) {
            SLIDER.reset();
        }

        /**
         * Uncheck and disable all DOWs
         */
        if (DOWS && DOWS.length > 0) {
            DOWS.forEach((dow) => {
                dow.checked = true;
            });
        }

        /**
         * Clear and disable input
         */
        if (INPUT) {
            INPUT.value = "";
        }

        /**
         * Apply the filters
         */
        FILTER();
    }, { passive: false });
}

/**
 * Filter the form when the page loads, and when the form is submitted
 */
if (FORM) {
    document.addEventListener("DOMContentLoaded", () => {
        FILTER();
    });

    FORM.addEventListener("submit", (e) => {
        e.preventDefault();
        FILTER();
    });
}

/**
 * Filter the form when a category is clicked
 */
if (CATS.length > 0) {
    CATS.forEach((cat) => {
        cat.addEventListener("click", () => {
            FILTER();
        });
    });
}

/**
 * Filter the form when a other_category is clicked
 */
if (OTHER_CATS.length > 0) {
    OTHER_CATS.forEach((cat) => {
        cat.addEventListener("click", () => {
            FILTER();
        });
    });
}

/**
 * Filter the form when the age range is changed
 */
if (SLIDER) {
    SLIDER.on("set", () => {
        /**
         * Get the values
         */
        const DATA = SLIDER.get();

        /**
         * Update hidden inputs
         */
        AGE_FROM.value = parseInt(DATA[0]);
        AGE_TO.value   = parseInt(DATA[1]);

        /**
         * Run the filter
         */
        FILTER();
    });
}

/**
 * Filter the form when a day-of-the-week is clicked
 */
if (DOWS.length > 0) {
    DOWS.forEach((dow) => {
        dow.addEventListener("click", () => {
            FILTER();
        });
    });
}
