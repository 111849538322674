// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const STICKY = document.querySelector(".sticky-block");

if (STICKY) {
    const OBSERVER = new IntersectionObserver(
        ([e]) => e.target.classList.toggle("js-is-sticky", e.intersectionRatio < 1),
        {
            rootMargin: document.getElementById("wpadminbar") ? "-32px 0px 0px 0px" : "0px 0px 0px 0px",
            threshold: [1],
        }
    );

    OBSERVER.observe(STICKY);
}
