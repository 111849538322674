// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import "regenerator-runtime";

const GET_DESCRIPTION = (id, endpoint) => {
    return wp.apiRequest({
        path: "pfest/v1/activenet",
        data: {
            endpoint: `${endpoint}/${id}`,
        },
    }).then(response => {
        return response.headers.response_code === "0000" && response.body.length > 0 && response.body[0].catalog_description ? response.body[0].catalog_description : "";
    }).catch(error => {
        /**
         * Output the error in the console
         */
        console.log(error);
    });
};

document.addEventListener("click", async (e) => {
    /**
     * Handle "View Description"
     */
    if (e.target && e.target.classList.contains("activity-list__title__button") && ! e.target.classList.contains("is-clicked") && e.target.dataset.ids) {
        /**
         * Mark the target as clicked so it can't be triggered again
         */
        e.target.classList.add("is-clicked");

        /**
         * Remove the "title" attribute as it's no longer important
         */
        e.target.removeAttribute("title");

        /**
         * Identify the endpoint
         */
        const ENDPOINT = e.target.dataset.endpoint;

        /**
         * Identify the destination
         */
        const TARGET = e.target.parentElement.nextElementSibling;

        /**
         * Show the loading message
         */
        TARGET.innerHTML = l10n.activenet.descriptions.loading;

        let i = 0;

        /**
         * Animate the loading message to show progress
         */
        const LOADER = setInterval(() => {
            i++;

            if (i < 4) {
                TARGET.innerHTML = `${TARGET.innerHTML}.`;
            } else {
                i = 0;

                TARGET.innerHTML = l10n.activenet.descriptions.loading;
            }
        }, 250);

        let description = "";

        /**
         * Sequentially loop over IDs until a description is found
         */
        for (const ID of JSON.parse(e.target.dataset.ids)) {
            /**
             * Attempt to retrieve a description for the ID
             */
            description = await GET_DESCRIPTION(ID, ENDPOINT);

            /**
             * If description was found, stop the loader, show it, and kill the loop
             */
            if (description) {
                /**
                 * Stop the loading animation
                 */
                clearInterval(LOADER);

                /**
                 * Insert the description
                 */
                TARGET.innerHTML = description;

                /**
                 * Break the loop
                 */
                break;
            }
        }

        /**
         * Clear the interval so it doesn't continue forever
         */
        clearInterval(LOADER);

        /**
         * Show a "not found" message if no description could be located
         */
        if (! description) {
            TARGET.innerHTML = l10n.descriptions.not_found;
        }

    /**
     * Handle "View Cost"
     */
    } else if (e.target && e.target.classList.contains("activity-table__link") && e.target.dataset.id) {
        const PARENT = e.target.parentElement;

        /**
         * Identify the endpoint
         */
        const ENDPOINT = e.target.dataset.endpoint;

        /**
         * Show the loading message
         */
        PARENT.innerHTML = l10n.activenet.fees.loading;

        let i = 0;

        /**
         * Animate the loading message to show progress
         */
        const LOADER = setInterval(() => {
            i++;

            if (i < 4) {
                PARENT.innerHTML = `${PARENT.innerHTML}.`;
            } else {
                i = 0;

                PARENT.innerHTML = l10n.activenet.fees.loading;
            }
        }, 250);

        /**
         * Get fee information from the ActiveNET API
         */
        wp.apiRequest({
            path: "pfest/v1/activenet",
            data: {
                endpoint: `${ENDPOINT}/${e.target.dataset.id}/fees`,
            },
        }).then(response => {
            /**
             * Stop the loading animation
             */
            clearInterval(LOADER);

            /**
             * Ensure response code is good and fees exist
             */
            if (response.headers.response_code === "0000" && response.body.length > 0) {
                let output = "";

                /**
                 * Process the fees (reversed) in to a string, with a separator
                 */
                response.body[0].normal_charges.reverse().forEach((fee, index) => {
                    if (fee.charge_type === "Fee") {
                        output = `${output}$${fee.default_amount} ${fee.charge_name}${index + 1 < response.body[0].normal_charges.length ? " / " : ""}`;
                    }
                });

                /**
                 * Show the fees
                 */
                PARENT.innerHTML = output;
            } else if (response.body.length === 0) {
                PARENT.innerHTML = l10n.activenet.fees.free;
            } else {
                /**
                 * Show the error message
                 */
                PARENT.innerHTML = l10n.activenet.fees.error;
            }
        }).catch(error => {
            /**
             * Stop the loading animation
             */
            clearInterval(LOADER);

            /**
             * Show the error message
             */
            PARENT.innerHTML = l10n.activenet.fees.error;

            /**
             * Output the error in the console
             */
            console.log(error);
        });
    }
}, { passive: true });
