// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Tabby from "tabbyjs";

const ELEMS   = document.querySelectorAll("[data-tabs]");
const BUTTONS = document.querySelectorAll(".tabbyjs__button");

const SYNC_BUTTONS = () => {
    if (window.location.hash) {
        const GROUPS = document.querySelectorAll(".tabbyjs");

        GROUPS.forEach((group) => {
            const ACTIVE = [];

            const BUTTONS = group.querySelectorAll(".tabbyjs__button");

            BUTTONS.forEach((button) => {
                if (button.getAttribute("href") === window.location.hash) {
                    button.classList.add("is-active");
                    ACTIVE.push(button);
                }
            });

            if (ACTIVE.length > 0) {
                BUTTONS.forEach((button) => {
                    if (!ACTIVE.includes(button)) {
                        button.classList.remove("is-active");
                    }
                });
            }
        });
    }
};

if (ELEMS.length > 0 && BUTTONS.length > 0) {
    ELEMS.forEach((elem) => {
        const ID = elem.getAttribute("id");

        if (ID) {

            const TABS = new Tabby(`#${ID}`);

            /**
             * When tab changes, sync to button
             */
            document.addEventListener("tabby", (e) => {
                const ID = e.detail.content.id;

                BUTTONS.forEach((button) => {
                    if (e.detail.content.closest(".tabbyjs").contains(button)) {
                        if (button.getAttribute("href") === `#${ID}`) {
                            button.classList.add("is-active");
                        } else {
                            button.classList.remove("is-active");
                        }
                    }
                });
            });

            /**
             * When button is clicked, open tab, scroll to button
             */
            BUTTONS.forEach((button) => {
                button.addEventListener("click", (e) => {
                    e.preventDefault();

                    TABS.toggle(button.getAttribute("href"));

                    button.scrollIntoView({ behavior: "smooth" });
                }, { passive: false });
            });

            /**
             * When the window loads, if hash exists, sync to button
             */
            document.addEventListener("DOMContentLoaded", () => {
                SYNC_BUTTONS();
            });

            /**
             * When the hash changes, attempt to toggle tab
             */
            window.addEventListener("hashchange", () => {
                if (window.location.hash.match(/^#tab-/) && document.querySelector(window.location.hash)) {
                    TABS.toggle(window.location.hash);
                    SYNC_BUTTONS();
                }
            });

        }

    });
}

