// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import transition from "transition-to-from-auto";

const OPEN = (link, content) => {
    link.classList.add("is-active");
    transition({ element: content, val: "auto" });
};

const CLOSE = (link, content) => {
    link.classList.remove("is-active");
    transition({ element: content, val: 0 });
};

const ARTICLES = document.querySelectorAll(".article--event-card");

if (false) {
    ARTICLES.forEach((article) => {
        const LINK    = article.querySelector(".article__link");
        const CONTENT = article.querySelector(".article__content");

        if (LINK && CONTENT) {
            /**
             * Open on hover
             */
            article.addEventListener("mouseenter", () => {
                OPEN(LINK, CONTENT);
            });

            /**
             * Close on unhover
             */
            article.addEventListener("mouseleave", () => {
                CLOSE(LINK, CONTENT);
            });

            /**
             * Open on hover
             */
            LINK.addEventListener("focus", () => {
                OPEN(LINK, CONTENT);
            });

            /**
             * Close on unhover
             */
            LINK.addEventListener("blur", () => {
                CLOSE(LINK, CONTENT);
            });
        }
    });
}
